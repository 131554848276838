import React from "react";
import Editor from "@monaco-editor/react";

const CodeEditor = () => {
  const handleEditorChange = (value, event) => {
    // do something with the value
  };

  return (
    <Editor
      height="100vh"
      defaultLanguage="javascript"
      defaultValue='console.log("Hello, world!");'
      theme="vs-dark"
      onChange={handleEditorChange}
    />
  );
};

export default CodeEditor;
